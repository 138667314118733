@import 'constants';
@import 'mixins';

.button {
  height: 44px;
  min-height: 44px;
  border-radius: 8px;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.3s ease;

  .icon {
    display: flex;
    margin-right: 8px;
  }

  &:hover {
    opacity: 0.8;
  }
}

.facebook {
  margin-bottom: 16px;
  background: #4560b4;
  border: 0;
  color: $rove-white;

  svg {
    fill: $rove-white;
  }
}

.google {
  background: $rove-white;
  border: 1px solid $rove-light-gray;

  svg {
    fill: #ff1407;
  }
}
