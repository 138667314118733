@import 'constants';
@import 'mixins';

.container {
  border-radius: 10px;
  border: 1px solid $rove-border-gray;
  box-shadow: 0 0 8px 0 rgba(207, 207, 207, 0.25), 0 4px 25px 0 #f5f5f5;
  background-color: $rove-white;
  font-family: Poppins, sans-serif;
  position: relative;

  @include media('<=laptop') {
    border: 0;
    background-color: #ffffff00;
  }

  .closeButton {
    position: absolute;
    top: 24px;
    right: 24px;
  }
}
