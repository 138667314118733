@import 'constants';

.container {
  display: flex;
  flex-direction: column;

  .inputHeader {
    display: flex;
    justify-content: space-between;

    span {
      margin-bottom: 7px;
      color: $rove-dark-black;
      font-family: 'Poppins', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 14px;

      &.forgotPassword {
        cursor: pointer;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
      }
    }
  }

  .inputContainer {
    margin-top: 0;
  }

  .input {
    font-family: 'Poppins', sans-serif;
    border: 1px solid $rove-dark-black;
    padding: 1vh 1vw;

    &:focus-visible {
      outline: 0;
    }
  }
}
