@import 'constants';
@import 'mixins';

.signUpLink {
  text-align: center;
  margin-top: 18px;
  font-size: 12px;
  line-height: 18px;

  a {
    text-decoration: underline;
    font-weight: 600;
    &:hover {
      cursor: pointer;
    }
  }
}
