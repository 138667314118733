@import 'mixins';
@import 'constants';

.singInButton {
  height: 44px;
  min-height: 44px;
  width: 100%;
  max-width: 381px;
  margin: 0 auto;
  border-radius: 8px;
  background: rgba(170, 212, 210, 0.5);
}
