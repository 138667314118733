@import 'mixins';
@import 'constants';

.formContainer {
  display: flex;
  flex-direction: column;
  padding: 7vh 7vw;

  @include media('<=laptop') {
    flex: 1;
    padding: 7vh 5vw;
  }

  .title {
    font-family: 'Poppins', sans-serif;
    font-weight: normal;
    font-size: 45px;
    line-height: 75px;
    text-align: center;
    margin: 0;

    @include media('<=laptop') {
      font-size: 30px;
    }
  }

  .subtitle {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    text-align: center;
    margin-bottom: 3vh;

    @include media('<=laptop') {
      font-size: 18px;
    }
  }

  .word {
    margin: 16px 0;
    color: $rove-dark-black;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
  }

  .signUp {
    text-align: center;
    margin: 2vh;
    font-size: 12px;
    line-height: 18px;
    font-family: 'Poppins', sans-serif;

    a {
      font-family: 'Poppins', sans-serif;
      text-decoration: underline;
      font-weight: 600;
      &:hover {
        cursor: pointer;
      }
    }
  }

  .loginContainer {
    margin-top: 10px;
  }

  .error {
    margin-bottom: 12px;
    color: $rove-red;
    font-size: 14px;
    text-align: center;
  }
}
